import React from 'react'
import { Helmet } from 'react-helmet'
import './site.css'

const NFS = () => {
  return (
    <>
      <Helmet>
        <title>Noah Settersten</title>

        <link
          href="https://fonts.googleapis.com/css?family=Muli"
          rel="stylesheet"
        />
        <link
          href="https://opensource.keycdn.com/fontawesome/4.7.0/font-awesome.min.css"
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>

      <body>
        <div class="header">
          <img
            src={require('./images/noah-settersten.png')}
            alt="Photograph of Noah Settersten"
          />
          <div class="headings">
            <p class="name">Noah Settersten</p>
            <p class="subtitle">
              • Wanderer • Worshiper • Father • Developer •
            </p>
          </div>
        </div>

        <h3>Development</h3>
        <div class="entries">
          <div class="card">
            <div class="logo">
              <img src={require('./images/logo-verve.png')} alt="Verve Logo" />
            </div>
            <p class="body">
              Implemented MapBox and Leaflet for a modern responsive and
              map-focused branch location page.
            </p>
            <div class="links">
              <a href="http://verveacu.com/locations-atms">Site</a>
            </div>
          </div>
          <div class="card">
            <div class="logo">
              <img src={require('./images/logo-c3.png')} alt="C3 Logo" />
            </div>
            <p class="body">
              New corporate website built on top of WordPress using the Roots.io
              stack for fast turnaround and deployment.
            </p>
            <div class="links">
              <a href="https://c3ingenuity.com">Site</a>
            </div>
          </div>
          <div class="card">
            <div class="logo">
              <img
                src={require('./images/logo-conover.png')}
                alt="Conover Logo"
              />
            </div>
            <p class="body">
              Contributed to a large Ruby on Rails application used for training
              emotional intelligence skills for a number of audiences.
            </p>
            <div class="links">
              <a href="https://conovercompany.com">Site</a>
            </div>
          </div>
          <div class="card">
            <h1>26 Letters</h1>
            <p class="body">
              Developed a survey and business awareness web application for
              promoting diversity and inclusion within the workplace.
            </p>
            <div class="links">
              <a href="http://26letters.com">Site</a> |{' '}
              <a href="http://26letters.io">App</a>
            </div>
          </div>
          <div class="card">
            <div class="logo">
              <img
                src={require('./images/logo-hivecast.png')}
                alt="Hivecast Logo"
              />
            </div>
            <p class="body">
              Mobile application for contributing to and producing multi-angle
              live video broadcasts for iOS.
            </p>
            <div class="links">
              <a href="http://hivecast.co">Site</a> |{' '}
              <a href="https://www.youtube.com/watch?v=AKTnSnqcZ4A">Demo</a>
            </div>
          </div>
          <div class="card">
            <div class="logo">
              <img
                src={require('./images/logo-hypremium.png')}
                alt="Hypremium Logo"
              />
            </div>
            <p class="body">
              Exclusive marketplace for purchasing hot sneakers, shirts, and
              other clothing from vetted and popular sellers. Mobile app built
              with React Native, and a Ruby on Rails backend and website.
            </p>
            <div class="links">
              <a href="http://www.hypremium.com">Site</a>
            </div>
          </div>
        </div>

        <h3>Travel Photography</h3>
        <div class="entries travel">
          <div class="card">
            <a href="images/travel/sensoji.jpg" target="_blank">
              <img
                src={require('./images/travel/sensoji_thumb.jpg')}
                alt="Senso-ji temple"
              />
            </a>
          </div>
          <div class="card">
            <a href="images/travel/hiroshima.jpg" target="_blank">
              <img
                src={require('./images/travel/hiroshima_thumb.jpg')}
                alt="Sakura blooming over the river running through central Hiroshima"
              />
            </a>
          </div>
          <div class="card">
            <a href="images/travel/miyajima1.jpg" target="_blank">
              <img
                src={require('./images/travel/miyajima1_thumb.jpg')}
                alt="Miyajima"
              />
            </a>
          </div>
          <div class="card">
            <a href="images/travel/miyajima2.jpg" target="_blank">
              <img
                src={require('./images/travel/miyajima2_thumb.jpg')}
                alt="Miyajima 2"
              />
            </a>
          </div>
          <div class="card">
            <a href="images/travel/fushimiinari.jpg" target="_blank">
              <img
                src={require('./images/travel/fushimiinari_thumb.jpg')}
                alt="Looking down at torii gates at Fushimi Inari in Kyoto"
              />
            </a>
          </div>
          <div class="card">
            <a href="images/travel/mutianyu.jpg" target="_blank">
              <img
                src={require('./images/travel/mutianyu_thumb.jpg')}
                alt="View overlooking the Great Wall of China at Mutianyu"
              />
            </a>
          </div>
          <div class="card">
            <a href="images/travel/forbiddencity.jpg" target="_blank">
              <img
                src={require('./images/travel/forbiddencity_thumb.jpg')}
                alt="Front view of the Forbidden City in Beijing"
              />
            </a>
          </div>
          <div class="card">
            <a href="images/travel/monastery.jpg" target="_blank">
              <img
                src={require('./images/travel/monastery_thumb.jpg')}
                alt="Monastery courtyard"
              />
            </a>
          </div>
          <div class="card">
            <a href="images/travel/tlg.jpg" target="_blank">
              <img
                src={require('./images/travel/tlg_thumb.jpg')}
                alt="Rice paddies and river running through Tiger Leaping Gorge"
              />
            </a>
          </div>
          <div class="card">
            <a href="images/travel/greece.jpg" target="_blank">
              <img
                src={require('./images/travel/greece_thumb.jpg')}
                alt="Grecian town"
              />
            </a>
          </div>
          <div class="card">
            <a href="images/travel/rome.jpg" target="_blank">
              <img
                src={require('./images/travel/rome_thumb.jpg')}
                alt="Front view of Trevi fountain in Rome"
              />
            </a>
          </div>
          <div class="card">
            <a href="images/travel/skye.jpg" target="_blank">
              <img
                src={require('./images/travel/skye_thumb.jpg')}
                alt="The Old Man of Storr on the Isle of Skye"
              />
            </a>
          </div>
        </div>

        <div class="footer">
          &copy; 2017 Noah Settersten.
          <div class="social-links">
            <a href="mailto:hello@noahsettersten.com">
              <i class="fa fa-envelope fa-lg"></i>
            </a>
            <a href="https://www.linkedin.com/in/settersten/">
              <i class="fa fa-linkedin fa-lg"></i>
            </a>
            <a href="http://github.com/noahsettersten">
              <i class="fa fa-github fa-lg"></i>
            </a>
          </div>
        </div>
      </body>
    </>
  )
}

export default NFS
